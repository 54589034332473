import { $GET } from "@request/http.js";
import * as Types from "../mutation-type.js";

const state = {
  sthjJcnrSelect: null, // 监测内容选择
  sthjJlfxxqSelect: null,
  sthjGsmmTypeName: null, //古树名木类型名称
  sthjDeviceItem: null, //某一个设备
};

const getters = {
  [Types.sthjJcnrSelect]: ({ sthjJcnrSelect }) => sthjJcnrSelect,
  [Types.sthjJlfxxqSelect]: ({ sthjJlfxxqSelect }) => sthjJlfxxqSelect,
  [Types.sthjGsmmTypeName]: ({ sthjGsmmTypeName }) => sthjGsmmTypeName,
  [Types.sthjDeviceItem]: ({ sthjDeviceItem }) => sthjDeviceItem,
};

const mutations = {
  [Types.SET_STHJJCNRSELECT]: (state, sthjJcnrSelect) => {
    state.sthjJcnrSelect = sthjJcnrSelect;
  },
  [Types.SET_STHJJLFXXQSELECT]: (state, sthjJlfxxqSelect) => {
    state.sthjJlfxxqSelect = sthjJlfxxqSelect;
  },
  [Types.SET_STHJGSMMTYPENAME]: (state, sthjGsmmTypeName) => {
    state.sthjGsmmTypeName = sthjGsmmTypeName;
  },
  [Types.SET_STHJDEVICEITEM]: (state, sthjDeviceItem) => {
    state.sthjDeviceItem = sthjDeviceItem;
  },
};

const actions = {
  // demo
  // async GetPOMonSASList({
  //     dispatch,
  //     commit
  // }, payload) {
  //     let result = await $GET('MOPublicopinionMonitor/GetPOMonSASList', payload);
  //     return result.RESULTVALUE;
  // },

  // 0.监测总览统计值
  async GetJczlTjz({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetJczlTjz", payload);
    return result.RESULTVALUE;
  },

  // 0.获取设备点位信息
  async GetMapPoint({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetMapPoint", payload);
    return result.RESULTVALUE;
  },

  // 1.微环境-气象实时数据
  async GetWhjQxSssj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetWhjQxSssj", payload);
    return result.RESULTVALUE;
  },
  // 1.微环境-气象统计图
  async GetWhjQxTjt({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetWhjQxTjt", payload);
    return result.RESULTVALUE;
  },
  // 1.微环境-风向玫瑰图  参数 lx 0 微环境 空气质量  1是微环境气象
  async GetFxfsMgt({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetFxfsMgt", payload);
    return result.RESULTVALUE;
  },

  // 2.虫情测报统计结果
  async GetCqcbResultStatis({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetCqcbResultStatis", payload);
    return result.RESULTVALUE;
  },
  // 2.虫情测报监测数据统计图
  async GetCqcbJcsjTjt({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetCqcbJcsjTjt", payload);
    return result.RESULTVALUE;
  },
  // 2.虫情测报监测数据统计图
  async GetResultStatis({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetResultStatis", payload);
    return result.RESULTVALUE;
  },

  // 3.古茶林病虫害统计
  async GetBhcdctj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetBhcdctj", payload);
    return result.RESULTVALUE;
  },
  // 3.虫害危险等级
  async GetChwxdj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetChwxdj", payload);
    return result.RESULTVALUE;
  },
  // 3.病虫害危害程度
  async GetBchWhcd({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetBchWhcd", payload);
    return result.RESULTVALUE;
  },
  // 3.病虫害统计图
  async GetGclch({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetGclch", payload);
    return result.RESULTVALUE;
  },
  // 3.获取结论报告
  async GetJlbg({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetJlbg", payload);
    return result.RESULTVALUE;
  },

  // 5.微环境空气质量实时数据
  async GetWhjKqzlSssj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetWhjKqzlSssj", payload);
    return result.RESULTVALUE;
  },
  // 5.微环境空气质量统计图
  async GetWhjKqzlTjt({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetWhjKqzlTjt", payload);
    return result.RESULTVALUE;
  },

  // 6.微环境-土壤实时数据
  async GetWhjTrSssj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetWhjTrSssj", payload);
    return result.RESULTVALUE;
  },
  // 6.土壤统计图
  async GetTrTjt({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetTrTjt", payload);
    return result.RESULTVALUE;
  },

  // 7.水文监测汇总
  async GetSwjcHz({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetSwjcHz", payload);
    return result.RESULTVALUE;
  },
  // 7.水文监测统计图
  async GetSwjcTjt({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetSwjcTjt", payload);
    return result.RESULTVALUE;
  },

  // 8.古树名木生长状况
  async GetSzzkStatistic({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetSzzkStatistic", payload);
    return result.RESULTVALUE;
  },
  // 8.古树名木科属统计
  async GetGsmmKstj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetGsmmKstj", payload);
    return result.RESULTVALUE;
  },
  // 8.古树名木分布情况
  async GetGsmmFbqk({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetGsmmFbqk", payload);
    return result.RESULTVALUE;
  },

  // 9.植物类型统计
  async GetZwlxHz({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetZwlxHz", payload);
    return result.RESULTVALUE;
  },
  // 9.植物多样性统计
  async GetDyxTj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetDyxTj", payload);
    return result.RESULTVALUE;
  },
  //（植物多样性年份下拉框）
  async GetZwdyxDateYear({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetZwdyxDateYear", payload);
    return result.RESULTVALUE;
  },
  // 9.植物多样性-基本信息
  async GetZwDetail({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetZwDetail", payload);
    return result.RESULTVALUE;
  },
  /**
   * 9.植物多样性样方
   */
  async GetZwdyxYf({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetZwdyxYf", payload);
    return result;
  },
  /**
   * （根据样方统计物种数量 ）参数-样方id - yfid
   */
  async GetZwdyxTjByYf({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSwzs/GetZwdyxTjByYf", payload);
    return result;
  },

  // 10.物候监测统计图
  async GetWhjcTjt({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetWhjcTjt", payload);
    return result.RESULTVALUE;
  },

  // 11.动物多样性物种统计
  async GetDwdyxWztj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetDwdyxWztj", payload);
    return result.RESULTVALUE;
  },
  // 11.动物多样性统计
  async GetDwdyxTj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetDwdyxTj", payload);
    return result.RESULTVALUE;
  },
  // 增加了GetDwdyxDateYear（动物多样性年份下拉框）
  async GetDwdyxDateYear({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetDwdyxDateYear", payload);
    return result.RESULTVALUE;
  },
  // 植物多样性详情
  async GetZwYfDetail({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetZwYfDetail", payload);
    return result.RESULTVALUE;
  },
  // 物候监测基本信息
  async GetWhjcPic({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetWhjcPic", payload);
    return result.RESULTVALUE;
  },
  // 结果统计
  async GetSthjJgtj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetSthjJgtj", payload);
    return result.RESULTVALUE;
  },
  // 动物样方详情
  async GetDwdyx({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetDwdyx", payload);
    return result.RESULTVALUE;
  },
  // 森林病虫害遥感统计
  // 有害生物类型
  async GetYgjcTj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetYgjcTj", payload);
    return result.RESULTVALUE;
  },
  // 监测样式统计
  async GetYfTj({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetYfTj", payload);
    return result.RESULTVALUE;
  },
  // 古茶林病虫害详情
  async GetGcychyfDetail({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetGcychyfDetail", payload);
    return result.RESULTVALUE;
  },
  // 古树名木测试数据 以后要删除
  async GetGsmmNewLayer({ dispatch, commit }, payload) {
    let result = await $GET("LargeShowSthj/GetGsmmNewLayer", payload);
    return result.RESULTVALUE;
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
